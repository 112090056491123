export const EVData = {
  years: [1872, 1876, 1880, 1884, 1888, 1892, 1896, 1900, 1904, 1908, 1912, 1916, 1920, 1924, 1928, 1932, 1936, 1940, 1944, 1948, 1952, 1956, 1960, 1964, 1968],
  "AL": [1.04192, 1.32877, 1.64323, 1.63305, 1.62213, 1.28571, 1.76282, 2.157, 2.87315, 3.22477, 2.88221, 3.2044, 2.54531, 3.94712, 3.34079, 3.35631, 3.43556, 3.51356, 4.06089, 4.70177, 3.00204, 2.5858, 2.49784, 1.90346, 1.29591],
  "AK": [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 6.3286, 5.85664, 4.91574],
  "AZ": [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 3.58298, 1.80497, 2.2718, 2.22267, 2.27879, 1.89923, 2.07708, 1.87907, 2.62587, 2.07585, 1.78522, 1.61009, 1.28665, 1.36556, 1.3971],
  "AR": [1.33793, 1.41201, 1.39724, 1.3962, 1.26582, 1.46804, 1.67035, 1.95919, 2.19657, 1.8274, 2.03821, 1.84698, 2.47035, 3.55999, 3.15564, 3.05473, 4.31209, 4.21335, 3.81852, 3.41069, 2.29829, 2.29826, 2.39304, 1.40576, 1.33919],
  "CA": [1.10767, 0.878707, 0.912722, 1.01885, 0.903993, 0.907323, 0.940438, 0.932224, 0.856159, 0.797383, 0.546847, 0.453995, 0.694106, 0.555707, 0.50155, 0.726186, 0.716681, 0.632501, 0.641547, 0.571236, 0.696682, 0.683752, 0.6304, 0.744186, 0.750508],
  "CO": [0.0, 0.0, 1.39982, 1.13145, 0.926666, 1.15795, 0.658075, 0.565728, 0.583052, 0.584152, 0.63696, 0.711517, 1.03554, 0.960617, 1.06031, 0.981374, 1.05547, 1.02708, 1.07341, 1.07007, 1.10738, 1.0569, 1.04459, 1.01395, 1.00636],
  "CT": [1.10508, 1.12081, 1.12882, 1.09667, 1.10669, 0.990805, 1.07286, 1.04267, 1.04066, 1.1357, 1.04163, 1.14255, 0.965306, 0.958239, 0.877372, 1.00794, 0.995659, 0.962024, 0.868782, 0.832036, 0.848154, 0.836442, 0.838541, 0.862014, 0.86646],
  "DE": [2.43097, 2.83613, 2.53843, 2.51009, 2.86263, 2.18989, 2.4326, 2.23731, 1.94368, 1.92638, 1.74551, 2.02135, 1.59384, 1.80878, 1.9638, 1.98923, 2.02108, 2.06735, 2.16221, 1.98219, 2.00478, 1.96869, 1.95512, 1.95664, 1.90411],
  "FL": [2.13112, 1.95098, 1.93614, 1.67278, 1.70833, 3.06506, 2.68307, 3.15913, 3.67059, 3.12261, 3.34385, 2.59435, 2.07599, 3.01209, 1.63951, 1.89694, 1.83819, 1.35501, 1.49778, 1.27262, 1.17547, 1.03895, 0.830085, 0.99125, 0.870659],
  "GA": [1.40032, 1.38891, 1.75551, 2.09631, 2.38437, 1.5836, 2.49493, 3.35297, 2.82002, 3.02441, 3.2654, 3.04157, 4.75999, 4.60542, 4.23474, 3.51479, 3.51866, 3.60816, 3.30447, 2.63318, 2.12796, 2.11252, 2.09744, 1.38295, 1.30589],
  "HI": [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.08191, 2.53396, 2.30396],
  "ID": [0.0, 0.0, 0.0, 0.0, 0.0, 4.20161, 3.15816, 1.6221, 1.17449, 0.950529, 1.07162, 1.03729, 1.48662, 1.47805, 1.82964, 1.60454, 1.72261, 1.59848, 1.73486, 1.71104, 1.684, 1.71144, 1.70651, 1.79575, 1.86906],
  "IL": [0.863648, 0.864466, 0.843122, 0.820503, 0.835535, 0.746669, 0.686046, 0.663964, 0.712662, 0.720464, 0.716844, 0.461693, 0.697831, 0.643349, 0.646881, 0.637044, 0.630097, 0.646136, 0.626815, 0.645804, 0.700711, 0.715529, 0.727466, 0.725923, 0.765744],
  "IN": [0.755263, 0.779707, 0.796255, 0.759721, 0.793402, 0.736441, 0.734151, 0.7073, 0.624775, 0.641218, 0.649346, 0.728431, 0.598653, 0.64599, 0.731538, 0.664628, 0.729006, 0.738013, 0.702461, 0.721264, 0.77318, 0.76897, 0.780354, 0.816096, 0.832913],
  "IA": [0.899005, 0.856885, 0.849573, 0.86463, 0.913592, 0.797328, 0.777255, 0.76757, 0.759901, 0.809966, 0.748069, 0.883007, 0.732076, 0.729161, 0.892643, 0.794339, 0.827505, 0.850527, 0.858372, 0.885033, 0.916584, 0.94609, 1.00627, 0.997633, 1.04847],
  "KS": [0.879648, 0.918957, 0.62082, 0.849314, 0.771888, 0.83656, 0.93209, 0.885168, 0.864803, 0.819976, 0.775163, 0.554271, 0.883811, 0.827181, 0.971906, 0.850733, 0.894426, 0.98315, 0.985065, 0.931919, 1.03814, 1.07869, 1.10402, 1.07137, 1.09124],
  "KY": [1.11019, 1.05046, 1.12248, 1.17961, 1.0706, 1.03661, 0.909062, 0.869348, 0.847314, 0.816649, 0.813573, 0.872586, 0.71325, 0.873704, 0.958015, 0.837669, 1.02096, 1.06566, 1.14512, 1.22869, 1.17096, 1.10837, 1.13992, 1.12965, 1.15971],
  "LA": [1.09925, 1.25164, 1.91928, 1.83457, 1.96054, 1.89263, 2.4688, 3.68913, 4.74376, 3.69343, 3.5751, 3.75437, 3.98788, 4.49335, 3.21157, 2.785, 2.60676, 2.52422, 2.58605, 2.20711, 1.78378, 1.89138, 1.58659, 1.46497, 1.23978],
  "ME": [1.3674, 1.36446, 1.21537, 1.15353, 1.32867, 1.40043, 1.57995, 1.77765, 1.75715, 1.73939, 1.31126, 1.53654, 1.52867, 1.71069, 1.58636, 1.25421, 1.41279, 1.46456, 1.52416, 1.73516, 1.65291, 1.66049, 1.51956, 1.37772, 1.38505],
  "MD": [1.05219, 1.11455, 1.15511, 1.07893, 1.07712, 1.01954, 0.996854, 0.947358, 1.01375, 1.03388, 0.977045, 1.06576, 0.941182, 1.22236, 1.04955, 1.17188, 1.10054, 1.13893, 1.18799, 1.2319, 1.16026, 1.12748, 1.09311, 1.17608, 1.10166],
  "MA": [1.19192, 1.14241, 1.15128, 1.1577, 1.15504, 1.04265, 1.16479, 1.13237, 1.02138, 1.07946, 1.04491, 1.18152, 0.913031, 0.872997, 0.790768, 0.805419, 0.794091, 0.788175, 0.737319, 0.697739, 0.780691, 0.795747, 0.830489, 0.783971, 0.81691],
  "MI": [0.878343, 0.788076, 0.779063, 0.812937, 0.776711, 0.816496, 0.800165, 0.80532, 0.75767, 0.79651, 0.771321, 0.804382, 0.721168, 0.708327, 0.757788, 0.854401, 0.904845, 0.856012, 0.778466, 0.827597, 0.831087, 0.758333, 0.772609, 0.860847, 0.864196],
  "MN": [0.972459, 0.919395, 0.828575, 0.924198, 0.755103, 0.915372, 0.82117, 0.890983, 1.06725, 1.02351, 1.01725, 1.08142, 0.822004, 0.79981, 0.856661, 0.821147, 0.836849, 0.82622, 0.883028, 0.833827, 0.927326, 0.95881, 0.914449, 0.84469, 0.856522],
  "MS": [1.09275, 1.10767, 1.71717, 1.87084, 2.2076, 4.65777, 4.03277, 4.77228, 4.83884, 4.60759, 4.3937, 4.02735, 6.11033, 4.87248, 4.56955, 4.6137, 4.7732, 4.81049, 4.5156, 4.30307, 3.2583, 3.76551, 3.43909, 2.24645, 1.45516],
  "MO": [0.971385, 0.976076, 0.936211, 0.909654, 0.871601, 0.853175, 0.786619, 0.778668, 0.794353, 0.775049, 0.730033, 0.798657, 0.680743, 0.75411, 0.831395, 0.697518, 0.70516, 0.768744, 0.86188, 0.87313, 0.799032, 0.828573, 0.861412, 0.86675, 0.9023],
  "MT": [0.0, 0.0, 0.0, 0.0, 0.0, 1.83398, 1.75414, 1.47116, 1.32273, 1.34375, 1.41968, 0.785884, 1.12634, 1.25664, 1.42841, 1.38326, 1.49173, 1.51655, 1.74295, 1.63885, 1.75513, 1.72291, 1.84711, 1.88501, 1.98334],
  "NE": [2.0457, 1.38753, 0.857098, 0.934694, 0.700806, 1.08617, 1.11775, 1.03762, 1.007, 0.924339, 0.908502, 0.972, 1.05356, 0.944419, 1.0135, 0.919134, 0.989693, 1.06814, 0.962685, 1.12762, 1.14451, 1.21427, 1.25442, 1.12388, 1.2672],
  "NV": [3.62133, 3.4759, 4.08582, 5.88124, 6.93944, 7.49586, 9.06994, 9.21358, 7.03604, 3.77068, 4.22549, 3.14342, 5.56061, 6.1064, 6.4148, 5.42084, 5.88157, 5.30208, 4.9979, 4.4379, 4.2448, 3.62402, 3.58488, 2.90854, 2.64676],
  "NH": [1.28312, 1.42342, 1.44666, 1.18637, 1.25067, 1.21708, 1.49075, 1.35612, 1.26059, 1.37619, 1.28839, 1.56669, 1.26732, 1.33027, 1.40917, 1.45703, 1.57652, 1.59678, 1.57391, 1.58814, 1.70425, 1.74987, 1.73355, 1.82308, 1.83061],
  "NJ": [0.946673, 0.932514, 0.914354, 0.865352, 0.841371, 0.805047, 0.840026, 0.780804, 0.788281, 0.79193, 0.916599, 0.988431, 0.780662, 0.706354, 0.626814, 0.735065, 0.755558, 0.761643, 0.736156, 0.75414, 0.769026, 0.752246, 0.739557, 0.784105, 0.804415],
  "NM": [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.7214, 1.56806, 1.4346, 1.45697, 1.76207, 1.48138, 1.52442, 1.53846, 2.37418, 1.9649, 1.94954, 1.83992, 1.64804, 1.60315, 1.66291],
  "NY": [0.747453, 0.786328, 0.792137, 0.7738, 0.774722, 0.731967, 0.788397, 0.728218, 0.684987, 0.733809, 0.802699, 0.920642, 0.782552, 0.755485, 0.708012, 0.75044, 0.721959, 0.700928, 0.672265, 0.69914, 0.734153, 0.741033, 0.791116, 0.787895, 0.861634],
  "NC": [1.07064, 0.975362, 1.03583, 1.02835, 1.09402, 1.06677, 1.03523, 1.17755, 1.64071, 1.46471, 1.39466, 1.44531, 1.12273, 1.3623, 1.30961, 1.36782, 1.33127, 1.4851, 1.60006, 1.62593, 1.34454, 1.40291, 1.31125, 1.19787, 1.11419],
  "ND": [0.0, 0.0, 0.0, 0.0, 0.0, 2.25761, 1.97396, 1.62555, 1.62333, 1.30448, 1.63617, 1.51264, 1.22476, 1.37625, 1.44489, 1.16839, 1.25627, 1.33884, 1.64149, 1.6653, 1.72205, 1.83945, 1.84145, 2.03265, 2.19555],
  "OH": [0.734793, 0.762053, 0.7582, 0.735416, 0.777315, 0.735324, 0.707094, 0.692476, 0.650666, 0.632171, 0.655647, 0.719096, 0.598386, 0.65227, 0.663223, 0.74583, 0.741919, 0.735991, 0.716386, 0.782421, 0.785607, 0.788713, 0.769966, 0.860099, 0.893388],
  "OK": [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.839905, 1.11631, 1.19243, 1.03584, 1.037, 1.12085, 1.16867, 1.26127, 1.2512, 1.25031, 1.27342, 0.980364, 1.08734, 1.1354, 1.12647, 1.15416],
  "OR": [2.63309, 2.29056, 1.83627, 1.42862, 1.37751, 1.38514, 1.28042, 1.50457, 1.26069, 1.11198, 1.03371, 0.667089, 1.05662, 0.980306, 1.08326, 1.01492, 1.03818, 0.976412, 1.12906, 1.05201, 1.00389, 0.952704, 0.990543, 1.00193, 0.996016],
  "PA": [0.910425, 0.871719, 0.828281, 0.832678, 0.854109, 0.867159, 0.83547, 0.854113, 0.781152, 0.826939, 0.884111, 1.02262, 1.03389, 0.970832, 0.836039, 0.942588, 0.747809, 0.829478, 0.833333, 0.861049, 0.81236, 0.816701, 0.819278, 0.789562, 0.831041],
  "RI": [3.7239, 3.42731, 3.4185, 3.06217, 2.78612, 2.04378, 2.27673, 2.21505, 1.65544, 1.70508, 1.81862, 1.98761, 1.50033, 1.30397, 1.46118, 1.12503, 1.10503, 1.17052, 1.20761, 1.12163, 1.12226, 1.20535, 1.2643, 1.34639, 1.4136],
  "SC": [1.29679, 0.874211, 1.02308, 2.43275, 3.19524, 3.46962, 4.07097, 5.54646, 4.63962, 4.17963, 5.06429, 4.91271, 6.79029, 9.7173, 9.09334, 5.73616, 5.95755, 7.53087, 6.99218, 5.15616, 2.72761, 3.10865, 2.65184, 2.00175, 1.63194],
  "SD": [0.0, 0.0, 0.0, 0.0, 0.0, 1.54185, 1.50369, 1.30307, 1.12092, 1.07433, 1.21779, 1.35366, 1.38296, 1.34393, 1.32351, 1.03817, 1.15984, 1.2188, 1.55728, 1.46962, 1.58071, 1.58991, 1.67289, 1.79182, 1.93497],
  "TN": [1.18515, 1.22912, 1.2347, 1.15799, 1.12222, 1.22741, 1.16606, 1.37213, 1.40461, 1.43837, 1.3495, 1.53902, 1.41117, 2.18987, 2.28847, 2.11011, 1.98435, 1.97726, 2.12305, 2.00384, 1.43323, 1.36769, 1.34054, 1.2626, 1.19865],
  "TX": [1.21331, 1.22041, 0.826886, 1.00256, 1.03273, 0.965791, 0.858577, 1.10858, 2.18562, 1.8889, 1.8571, 1.87446, 2.07156, 1.6668, 1.95533, 1.99418, 2.34409, 1.92213, 1.80652, 1.69134, 1.34447, 1.43348, 1.33111, 1.24965, 1.10459],
  "UT": [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.19751, 1.00809, 0.838403, 0.851462, 1.00838, 0.975473, 1.3826, 1.39619, 1.57, 1.44956, 1.58697, 1.51688, 1.45542, 1.33027, 1.41153, 1.39883, 1.36831, 1.31202, 1.28793],
  "VT": [1.66884, 1.76713, 1.91594, 1.68937, 1.73569, 1.94854, 1.95359, 2.22829, 2.19041, 2.34066, 1.8034, 2.16572, 2.2412, 2.12975, 2.05091, 1.63955, 1.79482, 1.97071, 2.16221, 2.23427, 2.27199, 2.29055, 2.29817, 2.41532, 2.52892],
  "VA": [1.05032, 1.0621, 1.29874, 1.0564, 1.12078, 1.11609, 1.26986, 1.42225, 2.61459, 2.69886, 2.48209, 2.7555, 2.61808, 2.94078, 2.724, 2.7639, 2.8262, 2.9825, 2.55833, 2.4109, 2.25197, 2.0081, 1.99385, 1.51175, 1.19921],
  "WA": [0.0, 0.0, 0.0, 0.0, 0.0, 1.2359, 1.33284, 1.16492, 0.978778, 0.838231, 0.614388, 0.641379, 0.884935, 0.909925, 0.968801, 0.974113, 0.993329, 0.947081, 0.844089, 0.812232, 0.949156, 0.913389, 0.929158, 0.938961, 0.938858],
  "WV": [1.41539, 1.14478, 1.10834, 1.13909, 1.06878, 0.953253, 0.927335, 0.850944, 0.828794, 0.836039, 0.843126, 0.963492, 0.790763, 0.751079, 0.862747, 0.805216, 0.828636, 0.86608, 1.01009, 0.981797, 1.06502, 1.12467, 1.22399, 1.16046, 1.26281],
  "WI": [0.91953, 0.887124, 0.935084, 0.862831, 0.880991, 0.87777, 0.836356, 0.849197, 0.832993, 0.881845, 0.920869, 1.01494, 0.934387, 0.847215, 0.886196, 0.80583, 0.819654, 0.80236, 0.809636, 0.863626, 0.868203, 0.903941, 0.88958, 0.931335, 0.965224],
  "WY": [0.0, 0.0, 0.0, 0.0, 0.0, 4.87244, 4.43522, 3.80533, 2.77589, 2.45897, 2.00955, 2.02018, 2.76445, 2.05745, 2.5104, 2.31622, 2.49459, 2.51188, 2.67472, 2.71796, 2.69925, 2.82294, 2.73144, 2.76011, 3.20882],
  "US": [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0],
}
